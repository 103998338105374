



















































































































































































































import { Component, Vue } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

@Component<Kooperatifcilik>({
  metaInfo() {
    return {
      title: this.pageTitle
    };
  },
  components: {
    Breadcrumbs
  }
})
export default class Kooperatifcilik extends Vue {
  pageTitle = "Kooperatifçilik Eğitim Programı";

  get breadcrumbs(): unknown[] {
    return [
      {
        text: "Ana Sayfa",
        exact: true,
        to: { name: "Home" }
      },
      {
        text: "Program Kataloğu",
        exact: true,
        to: { name: "ProgramCatalog" }
      },
      {
        text: this.pageTitle
      }
    ];
  }

  get isUserLoggedIn(): boolean {
    return this.$store.getters["auth/isUserLoggedIn"];
  }

  msgDialog = false;

  apply():void {
    if (this.isUserLoggedIn) {
      this.$router.push({ name: 'Apply', params: { programUrlCode: 'kooperatifcilik' }});
    } else {
      this.msgDialog = true
    }
  }
}
